@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;0,800;1,800&display=swap);

/** Home Page **/
@font-face{font-family:'Founders Grotesk';font-display:swap;
    src:url(../../static/media/FoundersGroteskWeb-Regular.8a216d68.woff2) format("woff2"),
    url(../../static/media/FoundersGroteskWeb-Semibold.38763d21.woff2) format("woff2");
    font-weight:600;
    font-style:normal
}
.complaince-text-p{
    font-size: 24px !important;
    text-align: left !important;
}
.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background: #000646;
    border: #000646;
}
h1,h3, h4{
    font-family: 'Montserrat', sans-serif;
}
@media only screen and (min-width:330px) and (max-width:700px) {
    .topbar-flex .btn {
        background: #c1c1c1;
        border: 1px solid #c1c1c1;
        color: #321260;
        font-weight: normal;
        padding-left: 8px !important;
        padding-right: 8px !important;
        font-size: 11px !important;
        border-radius: 25px;
        width: 100%;
        box-shadow: none !important;
    }
    .navbar .btn {
        margin-top: 20px;
        margin-bottom: 20px;
        padding-bottom: 10px !important;
    }
    .navbar-light .navbar-nav .nav-link{
        font-size: 20px !important;
        padding-bottom: 0px !important;
    }
    .navbar .btn{
        margin-top:20px;
        margin-bottom: 20px;
    }
    .topbar-flex .mobile-width-50 {
        padding-left: 5px;
        padding-right: 5px;
    }
}
/** Mobile Responsive Code Adjust **/
@media (max-width:700px) {
    .whyipowerz-content{
        margin-top:30px;
    }  
    .brand-block .mobile-service-left::after {
        width: 100px !important;
        height: 100px !important;
        position: absolute;
        border-radius: 50%;
        /* top: 10px; */
        margin-left: 10px;
        margin-top: -90px !important;
    } 
    .bannerTitle {
        font-size: 42px !important;
        color: #000646;
        line-height: 52px;
    }
    .ipowerz-title {
        font-size: 38px !important;
        text-align: left !important;
    }
    .launchIdea-title{
        font-size: 38px !important;
        text-align: left !important;
    line-height: 36px !important;
    }
    .ipowerz-title span {
        font-size: 22px !important;
        color: #f7560f;
    }
    .launchIdea-title span {
        font-size: 22px !important;
    }
    .wantFunds .idea-title-left::after{
        width:120px !important;
        height: 120px !important;
        margin-top: -120px !important;
        margin-left: 0px !important;
    }
    .idea-powerz-content p {
        margin-top: 50px;
        font-size: 18px !important;
    }
    .profit-idea-block p{
        font-size: 18px !important;
        font-family: "Founders Grotesk",-apple-system,BlinkMacSystemFont,"Helvetica Neue",Helvetica,Arial,sans-serif !important;
    }
    .complaince-text-p {
        font-size: 18px !important;
        text-align: left !important;
    }
    .form-check label {
        font-size: 22px !important;
    }
    .form-label{
        font-size: 22px !important;
    }
}
.profit-idea-block p{
    font-size: 18px !important;
    font-family: "Founders Grotesk",-apple-system,BlinkMacSystemFont,"Helvetica Neue",Helvetica,Arial,sans-serif !important;
}
body{
    font-weight:normal;
    line-height:1.5;
    color:#3C4067;
    font-family:"Founders Grotesk",-apple-system,BlinkMacSystemFont,"Helvetica Neue",Helvetica,Arial,sans-serif;
    overflow-y:auto;
    overflow-x:hidden;
    min-height:100%;
   font-size:18PX;

}
.wantFunds p{
    font-family: 'Montserrat', sans-serif;
}
.mentorTab p{
    font-family: 'Montserrat', sans-serif;
}
/**
Navbar **/
.navbar-light .navbar-nav .nav-link {
    color: #3d287b;
    font-size: 22px;
    font-weight: normal;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #3d287b;
}
@media (min-width: 992px) {
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .9rem;
    padding-left: .9rem;
}
}
.navbar {
    padding-top: .8rem;
    padding-bottom: .8rem;
    background: #f9fbfe !important;
    border-bottom: 1px solid #f7f9fc;
}
.navbar .btn{
    background: #f7560f;
    padding-left: 20px !important;
    padding-right: 20px !important;
    border-radius: 25px;
    box-shadow: none !important;
}
@media (min-width:700px) {
    .navbar .btn{
        margin-left: 0.7rem;
    } 
    .image1{
        padding-right: 30px;
    }
    .image2{
        padding-left: 30px;
    }
    .steps-first-row-height{
        min-height: 200px;
    }
    .steps-second-row-height{
        min-height: 300px;
    }
}
@media (max-width:700px) {
    .navbar-expand .navbar-toggler{
        display: block;
    }
    .navbar-nav {
        display: block;
        flex-basis: 100%;
    }
    .mobile-align-left{
        text-align: left !important;
    }
    .collapse:not(.show) {
        display: none !important;
    }
    .navbar-expand>.container, .navbar-expand>.container-fluid, .navbar-expand>.container-lg, .navbar-expand>.container-md, .navbar-expand>.container-sm, .navbar-expand>.container-xl {
        flex-wrap: wrap;
    }
    .navbar-expand .navbar-nav {
        flex-direction: column;
    }
    .navbar-brand img{
        width: 150px !important;
    }
    .navbar-expand .navbar-collapse.show {
        justify-content: inherit !important;
        flex-basis: 100%;
    }
}
.self-end{
    align-self: flex-end;
}
/*** Banner ***/
.banner{
    background: #ffffff;
    padding-top: 150px;
    padding-bottom: 150px;
}
.bannerTitle{
    font-size: 52px;
    color: #000646;
    line-height: 52px;
}
.bannerTitle span{
    color: #f7560f;
}
.banner-content p{
    color: #3C4067;
    font-size: 24px;
    font-weight: normal;
    margin-top: 30px;
}
.banners-btn{
    margin-top:30px;
}
.banners-btn .btn{
    background: #f7560f;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 25px;
    color: #3d287b;
    font-weight: 500;
    box-shadow: none !important;
    margin-right: 20px;
}
.search-plans .input-group>.form-control{
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border:none !important;
    padding-left: 30px !important;
    padding:10px;
}
.search-plans .btn{
    padding-left: 60px;
    background: #f7560f 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    padding-right: 60px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border: none !important;
    color: #ffff;
    font-weight: 500;
}
.searchForm{
    background: #f9fbfe;
    padding-top: 60px;
    padding-bottom: 60px;
}

.flex-block-search label {
    display: inline-block;
    font-size: 24px;
    color: #3C4067;
    font-weight: normal;
}
.flex-block-search .form-check{
    margin-right: 20px;
}
.ipowerz-title{
    font-size: 50px;
    color: #000646;
    text-align: center;
    position: relative;
    z-index: 999;
    line-height: 32px;
}
.ipowerz-title span{
    font-size: 24px;
    color: #f7560f;
}
.whyipowerz-content p{
    font-size: 24px;
    font-weight: normal;
    color: #3c4067 !important;
    margin-bottom: 0px;
}
.whyipowerz-content{
}
.why-powerz{
    background: #f9fbfe;
    padding-top: 40px;
    padding-bottom: 40px;
    /* padding-top: 80px;
    padding-bottom: 80px;
    */
}
.complaince-text-p{
    font-size: 18px !important;
}
.self-center{
    align-self: center;
}
@media (min-width:700px) {
    .border-left-seperator{
        border-left: 1px solid #d9d9d9;
    }
    .whyipowerz-content{
        padding-left: 30px;
    }
    .ipowerz-title {
        font-size: 50px;
        color: #000646;
        text-align: left;
    }
    .ipowerz-title-block{
        display: flex;
    display: flex;
    }
    .launchIdea-title-block{
        padding-right: 50px;
        display: flex;
    justify-content: center;
    display: flex;
    }
}
.justify-center{
    justify-content: center;
}
.ipowerz-title-block::after{
    content: '';
        width: 100px;
        height: 100px;
        background: url(../../static/media/iPowerz-logo-icon-01.a2552efc.svg);
        position: absolute;
    border-radius: 50%;
    /* top: 10px; */
    /* margin-top: -80px; */
    display: inline-block;
}
/** Launch Idea Block **/
.launchIdea-title-block::after{
    content: '';
        width: 100px;
        height: 100px;
        background: url(../../static/media/iPowerz-logo-icon-01.a2552efc.svg);
        position: absolute;
    border-radius: 50%;
    /* top: 10px; */
    /* margin-top: -80px; */
    display: inline-block;
}
.card-body {
    flex: 1 1 auto;
    padding: 1.5rem 1.5rem;
}
.card-title {
    margin-bottom: .5rem;
    color: #000646;
    font-size: 30px;
}
.card-text{
    font-size: 24px;
    color: #6b5264;
    font-weight: 300;
    margin-top:30px;
}
.card {
    border: 2px solid #e9e9e9 !important;
    border-radius: 10px !important;
}
.card-body {
    padding: 1.5rem 1.5rem;
    min-height: 180px;
}
.idea-powerz-title{
    font-size: 50px;
    color: #000646;
    text-align: inherit;
    position: relative;
    z-index: 999;
    line-height: 42px;
}
.idea-powerz-title span{
    font-size: 24px;
    color: #f7560f;
}
.idea-title-left::after{
    content: '';
    width: 100px;
    height: 100px;
    background: url(../../static/media/iPowerz-logo-icon-01.a2552efc.svg);
    position: absolute;
    border-radius: 50%;
    /* top: 10px; */
    margin-left: 10px;
    margin-top: -100px;
    display: inline-block;
}
.idea-powerz{
    padding-top:40px;
    padding-bottom: 80px;
}
.idea-powerz-content p{
    margin-top: 50px;
    font-size: 24px;
    color: #3C4067;
    font-weight: 300; 
}
.anchor-see-block{
    text-align: end;
    font-weight: 500;
}
.anchor-see-block a{
    text-decoration: none;
    color: #000646;
    border-bottom: 2px solid #f7560f;
    padding-bottom: 5px;
    font-size: 22px;
}
/** Top Bar **/
.top-bar{
    background: #eeeeee;
    padding-top: 20px;
    padding-bottom: 20px;
}

.topbar-flex .nav-link img{
    width:24px;
}
.topbar-flex .nav-link{
    color: #3C4067;
    font-size: 22px;
    font-weight: normal;
}
.topbar-flex .btn{
    background: #c1c1c1;
    border: 1px solid #c1c1c1;
    color: #321260;
    font-size: 22px;
    font-weight: normal;
    border-radius: 25px;
    box-shadow:none !important;
}
.topbar-flex .signInBtn{
    background: #f7560f;
    border: 1px solid #f7560f;
    color: #ffff;
    font-size: 22px;
    font-weight: normal;
    border-radius: 25px;
    box-shadow:none !important;
}
.btn-space-adjust{
    margin-right: 20px;
}
.justify-end{
    justify-content: flex-end;
}
.navbar-light .navbar-nav .nav-link{
    padding-bottom: 20px;
    border-bottom: 5px solid #f9fbfe;
}
.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    color: #f7560f;
}
.form-control::placeholder {
    color:#a68e7e;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:#a68e7e;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
    color:#a68e7e;
}
@media (min-width:962px) {
    .navbar{
        padding-top:10px !important;
        padding-bottom: 0px !important;
    }
    .navbar-brand{
        padding-top:15px;
        padding-bottom: 15px;
    }
    .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
        color: #3d287b;
        border-bottom: 5px solid #f7560f;
    }
    .navbar-light .navbar-nav .active {
        border-bottom: 5px solid #f7560f !important;
        color: #f7560f !important;
    }
}
/** Banner **/
@media (min-width:700px){
.banner{
    background-image: url(../../static/media/iPowerz-Banner.4804574f.png);
    background-repeat: no-repeat;
    background-position: right;
}
.BannerImageMobile{
    display: none;
}
}
@media (max-width:700px) {
    .banner {
        background: #f9fbfe !important;
    }
    .flex-block-search{
        display: flex;
        flex-wrap: wrap;
    }
    .flex-block-search .form-check{
        width:40%;
    }
    .col-spac{
        margin-top:30px;
    }
}
@media only screen and (min-width:1300px) and (max-width:1400px) {
    .banner{
        padding-top: 70px !important;
        padding-bottom: 70px !important;
        background-size: 100% !important;
    }
    .navbar-light .navbar-nav .nav-link {
        color: #3d287b;
        font-size: 16px !important;
        font-weight: normal;
    }
}
@media only screen and (min-width:1300px) and (max-width:1800px) {
    .banner{
        padding-top: 120px;
        padding-bottom: 120px;
    }
}
@media only screen and (min-width:1100px) and (max-width:1300px) {
    .banner{
        padding-top: 70px !important;
        padding-bottom: 70px !important;
        background-size: 100% 100% !important;
        background-image: url(../../static/media/iPowerz-Banner.4804574f.png), linear-gradient(to right, #010134 0%, #321260 35%,#8e8ea3 50%, #ebede8 65%,#fffffd 100%) !important;
    }
    .navbar-light .navbar-nav .nav-link {
        color: #3C4067;
        font-size: 16px !important;
        font-weight: normal;
    }
}
@media only screen and (min-width:950px) and (max-width:1100px) {
    .topbar-flex .nav-link {
        color: #3C4067;
        font-size: 14px !important;
        font-weight: normal;
    }
    .topbar-flex .btn{
        font-size: 12px !important;
    }
    .navbar-light .navbar-nav .nav-link {
        font-size: 12px;
        padding-right: .1rem;
    }
    .banner {
        padding-top: 70px;
        padding-bottom: 70px;
        background-size: 100% 100% !important;
    }
}
@media only screen and (min-width:700px) and (max-width:952px){
    .topbar-flex .nav-link {
        color: #3C4067;
        font-size: 10px !important;
        font-weight: normal;
    }
    .topbar-flex .btn{
        font-size: 10px !important;
    }
    .banner {
        padding-top: 70px;
        padding-bottom: 70px;
        background-size: 100% 100% !important;
    }
    .bannerTitle {
        font-size: 32px;
        color: #eeeeee;
        line-height: 32px;
    }
}
@media (min-width:700px) {
    .flex-block-search{
        display: flex;
    }
    .topbar-flex{
      display: flex;
    }
    .mobile-enable{
        display: none;
    }
    .mobile-top-bar{
        display: none;
    }
}
@media (max-width:700px) {
    .mobile-width-50{
        width:50%;
    }
    .topbar-flex{
      display: flex;
      flex-wrap: wrap;
    }
    .desktop-top-bar{
        display: none;
    }
    .topbar-flex .nav-link {
        color: #3C4067;
        font-size: 13px !important;
        font-weight: normal;
    }
    .navbar .btn {
        /* width: 80% !important; */
        background: #e56433;
        /* padding-left: 20px !important; */
        /* padding-right: 20px !important; */
        border-radius: 25px;
        box-shadow: none !important;
        margin-top: 30px;
    }
    .banner-content{
        background:transparent;
        padding: 20px;
        border-radius: 10px;
    }
    .our-services{
        background: #f9fbfe !important; 
    }
    .mobile-top-bar{
        display: block;
    }
    .mobile-enable{
        display: block;
    }
}
.form-check-input:checked {
    background-color: #000646;
    border-color: #000646;
}
/** Want Funds **/
.wantFunds{
    background:transparent;
    padding-top:0px;
    padding-bottom: 60px;
}
/**
Tab Lists Funds **/
.tab-funds .nav{
    display: block;
}
.image-icon img{
    width:64px;
}
.form-control {
    border-radius: 5px;
    display: block;
    width: 100%;
    height: auto !important;
    padding: .375rem .75rem;
    font-size: 16px;
    padding-bottom: 15px;
    padding-top: 15px;
    font-weight: 400;
    line-height: 1.5;
    color: #a68e7e;
}
.custom-file-input:lang(en)~.custom-file-label::after {
    content: '' !important;
}
.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + .75rem);
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #a68e7e;
    content: "Browse";
    background-color: #ffff;
    border-left: 0px;
    border-radius: 0 .25rem .25rem 0;
}
.custom-file-label{
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
    height: auto;
    color: #a68e7e;
}
.image-icon div{
    margin-top: 10px;
    font-size: 24px;
    color: #0b0245;
    font-weight: normal;
}
.self-center{
    align-self: center;
}
.sectionTitle{
    color: #ffbb33;
    margin-bottom: 1rem;
    font-size: 20px;
}
.powerz-content span{
    font-size: 24px;
    color: #ffffff;
    margin-top: 20px;
    margin-bottom: 20px;
}
.powerz-content p{
    margin-top: 1rem;
    font-size: 22px;
    color: #646161;
}
.powerz-content a{
    color: #ffbb33;
    font-size: 16px;
}
.powerz-content a img{
    width: 20px;
    /* padding-left: 20px; */
    margin-left: 10px;
}
.tab-funds .nav{
    flex-direction: row!important;
width: 100%;
}
.ipowerz .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    color: #fff;
    background-color: #ff6600;
    border-radius: 5px;
    border: 1px solid #ff6600;
    padding-top: 35px;
    padding-bottom: 35px;
    color: #111111;
    font-size: 22px;
    text-align: center;
    margin-top:20px;
    line-height: 24px;
}
.ipowerz .nav-pills .nav-link{
    font-size: 18px !important;
}
.tab-funds .nav-pills .active .st0{
    fill:#ffffff;
}
.tab-funds .nav-pills .active:before {
    content: "";
    width: 25px;
    height: 25px;
    background-color: #ff6600;
    border-radius: 5px;
    border: 1px solid #ff6600;
    transform: rotate(
45deg
);
    position: absolute;
    z-index: .5;
    /* right: 4px; */
    margin-top: 60px;
    right:-10px;
    border-top:0px !important;
    border-left: 0px !important;
}
/*  .ideas .nav-pills{
margin-left: 20px;
} */
.ideas .nav-pills .nav-link.active::before{
    content:'' !important;
    bottom: -10px !important;
width: 20px;
height: 20px;
    border:1px solid #ffffff;
    background: #ffff;
    border: 1px solid #f90;
    background: #ffff;
    position: absolute;
    transform: rotate(
45deg
);
    left: 45%;
    z-index: .5;
    border-top: none !important;
    border-left: none !important;
}
@media (min-width:700px) {
    .work-image{
        margin-top:-100px;
    }
    .work-image img{
        width: 100%;
    }
    .ideas .nav-pills .nav-link.active::after{
        content:'';
        width:30px;
        height:30px;
        border:1px solid #ffffff;
        background: #ffff;
        border: 1px solid #f90;
        background: #ffff;
        position: absolute;
        bottom: 10px;
        transform: rotate(
    45deg
    );
        left: 45%;
        z-index: .5;
        border-top: none !important;
        border-left: none !important;
    }
}
.form-label {
    font-size: 28px;
    color: #000646;
    margin-bottom: 15px;
    line-height: 24px;
}
.ipowerz .nav-pills .nav-link{
    border:1px solid #ccc;
    color: #fff;
    background-color: #fff;
    border-radius: 5px;
    padding-top: 35px;
    padding-bottom: 35px;
    color: #111111;
    font-size:  22px;
    text-align: center;
    margin-top:20px;
    line-height: 24px;
}
.submitButton{
    background: #000646;
    border: #000646;
    text-transform: capitalize;
    font-size: 24px;
    outline: none;
    padding-left: 40px;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 40px;
    text-align: center;
}
.btn-primary:hover {
    color: #fff;
    background-color: #f7560f;
    border-color: #f7560f;
}
.content-tab{
    background: #ffffff;
    padding: 30px;
    margin-top: 20px;
    width: 100%;
    border-radius: 5px;
}
.mentorTab{
    padding-bottom: 60px;
}
.padding-left-none{
    padding-left: 0px !important;
}
.no-padding-right{
    padding-right: 0px !important;
}
.ruppe-symbol{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    background-color: #f7f7f7;
}
.border-left-radius-none{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
/** Custom File **/
.span-file-picker-btn input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    background: red;
    cursor: inherit;
    display: block;
}
.span-file-picker-btn{
    position: relative;
  overflow: hidden;
  background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border-radius: 5px;
    display: block;
    width: 100%;
    height: auto !important;
    padding: .375rem .75rem;
    font-size: 16px;
    padding-bottom: 15px;
    padding-top: 15px;
    font-weight: 400;
    line-height: 1.5;
    color: #a68e7e;
}
.ipowerz-cards{
    padding-top: 80px;
    padding-bottom: 80px;
}
.ipowerz-propsers{
    background: #f9fbfe;
    padding-top: 80px;
    padding-bottom: 80px;
}
.nav-item{
    position: relative;
}
@media (min-width:900px) {
    .width-desktop-version-carousel{
        width:450px;
    }
}
.owl-theme .owl-nav.disabled + .owl-dots{
    display: none;
}
@media only screen and (min-width:1400px) and (max-width:1600px) {
    .left-margin-adjust{
        margin-left: 7%;
    }
    body{
        overflow-x: hidden;
    }
}
@media only screen and (min-width:1250px) and (max-width:1400px){
    .left-margin-adjust{
        margin-left: 9.5%;
      }
}
@media only screen and (min-width:1000px) and (max-width:1250px) {
    .left-margin-adjust{
        margin-left: 4%;
    }
}
@media only screen and (min-width:700px) and (max-width:900px) {
    .left-margin-adjust{
        margin-left: 4%;
    }
    .width-desktop-version-carousel{
        width:350px;
    }
}
@media only screen and (min-width:300px) and (max-width:700px) {
    .left-margin-adjust{
        margin-left: 15px;
    }
    /* .width-desktop-version-carousel{
        width:250px;
    } */
    
}
body{
    overflow-x: hidden !important;
}
@media only screen and (min-width:1600px) and (max-width:1900px) {
    .left-margin-adjust{
      margin-left: 11%;
    }
}
@media only screen and (min-width:1900px) and (max-width:2000px) {
    .left-margin-adjust{
        margin-left: 15.5%;
    }
}
@media only screen and (min-width:2000px) and (max-width:2300px) {
    .left-margin-adjust{
        margin-left: 18%;
    }
}
@media only screen and (min-width:2300px) and (max-width:3000px) {
    .left-margin-adjust{
        margin-left: 21.5%;
    }
}
@media (min-width:3000px) {
    .left-margin-adjust{
        margin-left: 28.5%;
    }
}
.e-ideas{
    background-image: linear-gradient(#9e9e9e 2%, #17295b00 25%, #17295b00 50% , #9e9e9e4d 100%),url(../../static/media/iPowerz-EnterprenuaIdeas.8a712cfe.png);
    background-size: cover;
    padding-top:130px;
    padding-bottom: 50px;
}
.cards-carousel .card-text{
    margin-top:0px !important;
}
.cards-carousel .card-title{
    min-height: 90px;
}
.cards-carousel{
    background-size: cover;
    padding-top:80px;
    padding-bottom: 50px;
}
.c-dideas{
    background-image: linear-gradient(#9e9e9e 2%, #17295b00 25%, #17295b00 50% , #9e9e9e4d 100%),url(../../static/media/ipowerz-BusinessGrowthIdeas.8c3fa50a.png);
}
.b-gideas{
    background-image: linear-gradient(#9e9e9e 2%, #17295b00 25%, #17295b00 50% , #9e9e9e4d 100%),url(../../static/media/ipowerz-CommunityDevelopmentIdeas.f28de456.png);
}
.width-desktop-version-carousel .card-title{
    color: #ffffff !important;
}
.width-desktop-version-carousel .card-text{
    font-size: 22px;
    color: #ffffff !important;
    font-weight: normal;
}

/** Our Serives **/
.our-services{
    background-image: linear-gradient(to left, #f9fbfe 56%, #f9fbfe 43%);
    padding-top: 80px;
    padding-bottom: 80px;
    background-size: cover;
}
.service-title{
    font-size: 50px;
    color: #000646;
    text-align: inherit;
    position: relative;
    z-index: 999;
    line-height: 42px;
}
.service-title span{
    font-size: 24px;
    color: #f7560f;
}
.service-title-block::after{
    content: '';
    width: 100px;
    height: 100px;
    background: url(../../static/media/iPowerz-logo-icon-01.a2552efc.svg);
    position: absolute;
    border-radius: 50%;
    /* top: 10px; */
    margin-left: -10px;
    margin-top: -100px;

    display: inline-block;
}
.service-content p{
font-size: 24px;
margin-top:50px;
font-weight: normal;
}
.service-content{
    padding:30px;
}
.serviceBtn .btn{
    background: #fcfcfc;
    border-color: #fcfcfc;
    color: #070044;
    border-radius: 25px;
    margin-right: 10px;
    font-size: 15px;
    margin-top:20px;
    box-shadow: none !important;
}
.serviceBtn{
    margin-top:30px;
}
.serviceBtn .btn:hover{
    background: #f7560f;
    border-color: #f7560f;
    color:#ffffff;
}
@media (min-width:952px) {
    .mobile-not-image{
        display: none;
    }
}
.know-more{
    color:#000646;
    text-decoration: none;
    border-bottom: 2px solid #f7560f;
    padding-bottom: 10px;
    font-size: 22px;
}
.know-more-block{
    margin-top:50px;
}
/** Launch Idea Section **/
.launch-ideas-block{
    padding-top: 80px;
}
.launchIdea-title{
    font-size: 50px;
    color: #000646;
    text-align: center;
    position: relative;
    z-index: 999;
    line-height: 42px;
}
.launchIdea-title span{
    font-size: 24px;
    color: #f7560f;
}
.launch-step::before{
    content: '';
    width: 20px;
    height: 20px;
    background: #f7560f;
    border-radius: 50%;
    position: absolute;
    border: 1px solid #fff;
    margin-top: -1px;
    margin-left: -10px;
}
.launch-step{
    position: relative;
    border-left: 3px solid #110a4b;
}
.launch-step-title{
    font-size: 28px;
    color: #000646;
    line-height: 24px;
    margin-bottom: 15px;
}
.launch-step-content p{
    font-size: 22px;
    margin-bottom: 0px;
    padding-bottom: 20px;
}
.launch-step-content{
    padding-left: 24px;
}
.launch-step-border-none{
    border-left: transparent !important;
}
.case-studies{
    background: #f9fbfe;
    padding-top: 80px;
    padding-bottom: 80px;
}
/** Case Studies **/
.iframe iframe{
    border-radius: 10px;
}
.case-title{
    font-size: 28px;
    color: #00006a;
    margin-top: 30px;
}
.case-studies p{
    font-size: 24px;
    margin-top:1rem;
}
.case-know-more{
    margin-top:50px;
}
.case-know-more a{
    color: #000646;
    border-bottom: 2px solid #f7560f;
    text-decoration: none;
    padding-bottom: 10px;
    font-size: 22px;
}
.brand-logo{
    margin-top: 30px;
    border: 1px solid #d3d3d3;
    text-align: center;
    border-radius: 5px;
}
.brand-logo img{
    width:auto;
}
.brand-block{
    background: #ffff;
    padding-top: 80px;
    padding-bottom: 80px;
}
/** NewsLetter **/
.newsletter{
    background: #000646;
    padding-top:50px;
    padding-bottom: 50px;
}
.newsletter-title{
    font-size: 32px;
    color:#ffffff
}
@media (min-width:700px) {
    .newsletter-image{
        padding-right: 50px;
    }
}
.newsletter-image img{
    width:60%;
}
/** Footer **/
.footer-part{
    background: #ffffff;
    padding-top:80px;
    padding-bottom: 40px;
}
.footer-block p{
    font-size: 24px;
    color:#3c4067 !important;
    margin-top:20px;
}
.social-icons{
    margin-top:30px;
    margin-bottom: 30px;
    list-style: none;
    display: flex;
    padding-left: 0px;
}
.social-icons img{
    width:24px;
    margin-right: 10px;
}
.footer-title{
    font-size: 28px;
    color:#000646;
    margin-bottom: 20px;
}
.footer-part a{
    color:#3c4067;
    font-size: 24px;
    text-decoration: none;
    display: block;
}
.footer-logo{
    width:200px;
}
.footer-block p span{
    color: #f7560f;
}
/** Copyright **/
.copyright{
    border-top: 2px solid #ccc;
    padding-top: 30px;
    margin-top: 40px;
    text-align: center;
}
.copy-right-title{
    font-size: 24px;
    color: #3c4067;
}
.copy-right-title a{
    display: contents !important;
}
.copy-right-title span{
    color: #f7560f;
}
html, body {overflow-x: hidden !important}
@media (max-width:700px) {
    .our-services{
        background-image: linear-gradient(to left, #f9fbfe 56%, #fdfdfd 43%) !important;
    }
    .serviceBtn .btn{
        width:100%;
    }
    .tab-funds .nav .nav-item {
        width: 50%;
    }
    .tab-funds .nav {
        flex-direction: row!important;
        width: 100%;
        display: flex!important;
    }
    .image-icon div {
        font-size: 18px!important;
    }
    .tab-funds .nav .mobile-space-tab {
    padding-left: 10px;
    }
    .mentor-funds .nav-pills .nav-link{
        min-height: 200px;
    }
    .profit-idea-block {
        background: #f9fbfe;
        padding: 30px;
        border-radius: 5px;
        margin-top: 30px;
    }
    .tab-funds .nav-pills .active:before {
        content: "";
        width: 25px;
        height: 25px;
        background-color: #ff6600;
        border-radius: 5px;
        border: 1px solid #ff6600;
        transform: rotate( 
    45deg
     );
        position: absolute;
        z-index: .5;
        bottom: -10px !important;
        /* right: 4px; */
        left: 50%;
        margin-top: 0px !important;
        right: -10px;
        border-top: 0px !important;
        border-left: 0px !important;
    }
    .mobile-after-block::after {
        /* top: 10px; */
        margin-top: -90px !important;
    }
    .col-span{
        margin-top:30px;
    }
    .service-content{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .service-title-block::after {
        content: '';
        width: 120px !important;
        height: 120px !important;
        background: url(../../static/media/iPowerz-logo-icon-01.a2552efc.svg);
        position: absolute;
        border-radius: 50%;
        /* top: 10px; */
        margin-left: 0px !important;
        margin-top: -120px !important;
        display: inline-block;
    }
    .launchIdea-title-block::after {
        content: '';
        width: 120px;
        height: 120px;
        background: url(../../static/media/iPowerz-logo-icon-01.a2552efc.svg);
        position: absolute;
        border-radius: 50%;
        /* top: 10px; */
        margin-left: 0px !important;
        margin-top: -120px !important;
    }
    .idea-powerz-title{
        font-size: 38px !important;
        color: #000646;
        text-align: inherit;
        position: relative;
        z-index: 999;
        line-height: 38px !important;
    }
    .idea-powerz-title span{
        font-size: 18px !important;
        color: #f7560f;
    }
    .service-title{
        font-size: 38px !important;
        color: #000646;
        text-align: inherit;
        position: relative;
        z-index: 999;
        line-height: 38px !important;
    }
    .service-title span{
        font-size: 18px !important;
        color: #f7560f;
    }
    .mobile-service-left::after{
        width: 120px;
        height: 120px;
        background: url(../../static/media/iPowerz-logo-icon-01.a2552efc.svg);
        position: absolute;
        border-radius: 50%;
        /* top: 10px; */
        margin-left: 10px;
        margin-top: -120px;
    }
    .newsletter-image{
        text-align: center;
    }
    .newsletter-image img{
        width:120px !important;
    }
    .newsletter-title {
        font-size: 28px !important;
        color: #ffffff;
        text-align: center !important;
    }
}
@media only screen and (min-width:1000px) and (max-width:1300px) {
    .serviceBtn .btn{
        font-size: 13px !important;
    }
}
.mentor-funds .nav-pills .nav-link{
    border: 1px solid #ccc;
    color: #fff;
    background-color: #fff;
    border-radius: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #111111;
    font-size: 22px;
    text-align: center;
    margin-top: 20px;
    line-height: 24px;
}
.mentor-funds .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color: #ff6600;
    border-radius: 5px;
    border: 1px solid #ff6600;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #111111;
    font-size: 22px;
    text-align: center;
    margin-top: 20px;
    line-height: 24px;
}
p{
    color:#3C4067 !important;
}
.profit-idea-block p{
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 30px;
}
.profit-idea-block p span{
    color:#ff6600;
}
.profit-idea-block{
    background: #f9fbfe;
    padding: 30px;
    border-radius: 5px;
}
.mentor-funds .nav-pills .active:before {
    content: "";
    width: 25px;
    height: 25px;
    background-color: #ff6600;
    border-radius: 5px;
    border: 1px solid #ff6600;
    transform: rotate( 
45deg
 );
    position: absolute;
    z-index: .5;
    /* right: 4px; */
    margin-top: 60px;
    /* right: -10px; */
    bottom: -10px;
    border-top: 0px !important;
    border-left: 0px !important;
}
.bg-gradient{
    background: #f9fbfe;
    padding-top: 50px;
    padding-bottom: 30px;
}
.bg-gradient-image{
    background-image: url(../../static/media/iPowerz-bg-01.c878c3bf.png);
    background-position: inherit;
    padding-bottom: 30px;
}
.bg-gradient-circle-image{
    background-image: url(../../static/media/iPowerz-bg-01.c878c3bf.png);
    background-position: inherit;
}
@media only screen and (min-width:1800px) and (max-width:4000px) {
    .our-services{
        background:url(../../static/media/iPowerz-Services.78dbe9e1.png) no-repeat, linear-gradient(to left, #f9fbfe 58%, #fdfdfd 43%);
        padding-top: 80px;
        padding-bottom: 80px;
        background-size: contain;
    }
    .mobile-visible{
        display: none;
    }
}
@media only screen and (min-width:700px) and (max-width:1800px) {
    .our-services{
        background-image: linear-gradient(to left, #f9fbfe 56%, #fdfdfd 43%) !important;
    }
}
@media only screen and (min-width:1400px) and (max-width:1550px) {
    .service-content{
        padding-left: 60px;
    }
}
/** Profile Icons **/
.profiles-block{
    padding-top: 80px;
    padding-bottom: 80px;
}
.profiles-block .card{
    transition: opacity .2s ease-in-out, box-shadow .2s ease-in-out;
    position: relative;
    border-radius: 7px;
    padding: 30px;
    box-shadow: 0px 58px 115px rgb(0 6 70 / 4%);
    border:none !important;
    margin-top:30px;
}
.profiles-block .card .card-body{
    min-height: auto !important;
    padding:0px;
    text-align: center;
}
.profiles-block .card .card-body .profile-icons img{
    width:64px;
}
.profiles-block .card .card-title{
    font-size: 24px;
    color: #0c0567 !important;
    line-height: 24px;
    margin-top: 20px;
    min-height: 50px;
    margin-bottom: 0px !important;
}
.cards-carousel{
    min-height: 720px;
}
@media only screen and (min-width:700px) and (max-width:1400px) {
    .profiles-block .card .card-title{
        font-size: 18px !important;
    }
}
.form-check-input {
    width: 1em;
    height: 1em;
    margin-top: .5em;
}
.text-area .form-control{
    font-style: italic;
}
.ipowerz-title{
    line-height: 42px;
}
.form-control{
    font-size: 24px;
}
.footer-part a:hover{
    color:#f7560f;
}
/*** About Us **/
.aboutUs{
    padding-top:100px;
}
.our-services-block .card:hover{
    background: #f7560f;
        border: 2px solid #f7560f !important;
}
.our-services-block .card:hover .service-icon-title{
    color: #fff !important;
}
.service-accordians .accordion-button:not(.collapsed){
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.service-accordians .no-arrow-scrollButton .accordion-button::after {
    background-image: none !important;
}
.service-accordians .no-arrow-scrollButton .accordion-button{
    background: #ffffff !important;
}
.service-accordians .no-arrow-scrollButton .accordion-button:not(.collapsed){
    background-color: #ffffff !important;
}
.service-accordians .accordion-button:not(.collapsed){
    background: #f9fbfe;
    background-color: #f9fbfe !important;
    color:#ffffff;
    box-shadow: none !important;
}
.our-services-block .card{
    border: 2px solid #7f7d7d !important;
}
.about-powerz-title{
    font-size: 50px;
    color: #000646;
    text-align: center;
    position: relative;
    z-index: 999;
    line-height: 42px;
}
.about-title-center {
    padding-right: 50px;
    display: flex;
    justify-content: center;
    display: flex;
}
.about-title-center::after{
    content: '';
    width: 100px;
    height: 100px;
    background: url(../../static/media/iPowerz-logo-icon-01.a2552efc.svg);
    position: absolute;
    border-radius: 50%;
    /* top: 10px; */
    margin-top: -30px;
    display: inline-block;
}
/** Vison & Mission **/
.aboutUs-title{
    color: #000646;
    font-size: 32px;
    font-weight: bold;
}
.visionMission p{
    font-size: 22px;
    margin-bottom: 0px;
    padding-bottom: 20px;
}
/** WHat We Do **/
.whatWeDo p{
    font-size: 22px;
    margin-bottom: 0px;
    padding-bottom: 20px;
}
/** Ipowerz Fast Track **/
.ipowerzFastTrack .accordion-item {
    background-color: #fff;
    border: none;
    color: #000646;
    border-bottom: 2px solid #d3d3d3 !important;
    outline: none !important;
}
.ipowerzFastTrack .accordion-button {
    position: relative;
    display: flex;
    color: #000646;
    padding:20px;
    font-size: 24px;
    outline: none !important;
}
.accordion-button:focus{
    box-shadow: none !important;
    border:none !important;
}
.accordion-button::after{
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAByQAAAckBYQ9UXAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEESURBVHic7ZoxCsMwEAT3gsEp86+8V3lXSqvaNHIIAZfRBLwDhjMYPAxcJZVtUVRVkyTbd8yBClBVq6RtvF5td8LjQvx0UAfzVMgAf0EC0AI0CUAL0CQALUCTALQATQLQAjQJQAvQJAAtQJMAtABNAtACNAlAC9AkAC1AkwC0AE0C0AI0i/Q+qJx9Prd+zlXTjwe9H8g2ST7p006/AmWbXIHnmG+SZt8PsO2+jGn65YSvne+2t6Nvf8npVyABaAGaBKAFaBKAFqBJAFqAJgFoAZoEoAVoEoAWoEkAWoAmAWgBmgSgBWgSgBagSQBagCYBaAEaMoAP5qks1I9t96p67DPl8QI5/HFCzSbuoQAAAABJRU5ErkJggg==);
}
.ipowerzFastTrack .accordion-button:not(.collapsed) {
    background-color: #f9fbfe;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    color: #f7560f !important;
}
.ipowerzFastTrack .accordion-body p{
    font-size: 24px;
}
.accordion-button:not(.collapsed)::after{
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABAAgMAAADXB5lNAAAAA3NCSVQICAjb4U/gAAAACXBIWXMAABUXAAAVFwHePnLjAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAAlQTFRF////AAAAAAAAflGpXQAAAAJ0Uk5TAIiV8MYqAAAAHElEQVQ4jWNgGAUjFzCGogmwoAswEdQyCkYyAAA93gEId4WVlQAAAABJRU5ErkJggg==);
}
/** Team Seciton **/
.team-about-block{
    background: #f9fbfe;
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 24px;
    text-align: center;
}
.team-about-block p a{
    color: #f7560f;
    text-decoration: none;
}
@media (max-width:700px) {
    .mobile-hide{
        display:none;
    }
}
.Team-title{
    padding-top:100px;
}
.profile-block{
    text-align: center;
}
.profile-block .profile-image img{
    width: 200px;
}
.profile-title{
    font-size: 28px;
    color: #000646;
}
.profile-title a {
    font-size: 28px;
    color: #000646;
    text-decoration: none;
}
.profile-title a:hover{
    color:#f7560f;
}
.profile-profession{
    color: #3C4067 !important;
    font-size: 22px;
}
.team-ipowerz{
    padding-top:60px;
}
.bg-team{
    background-image: url(../../static/media/iPowerz-bg-01.c878c3bf.png);
    background-size: cover;
    padding-bottom: 60px;
}
.aboutUs-bg{
    background-image: url(../../static/media/iPowerz-bg-01.c878c3bf.png);
    background-size: cover;
}
.contactUsForm{
    padding-top:50px;
    padding-bottom: 50px;
}
.contactUsForm p{
    font-size: 24px;
}
.contactUsForm p a{
    color: #f7560f;
    text-decoration: none;
}
.ipowerzFastTrack{
    padding-bottom: 40px;
}
.coming-soon{
    min-height: 350px;
    display: flex;
    align-self: center;
    align-items: center;
    text-align: center;
    font-size: 32px;
    color: #000646;
}
.privacyPolicy{
    padding-top: 60px;
}
.privacyPolicy p{
    font-size: 22px;
    margin-bottom: 0px;
    padding-bottom: 20px;
}
.privacyPolicy .accordion-button {
    position: relative;
    display: flex;
    color: #000646;
    padding: 20px;
    font-size: 24px;
    outline: none !important;
}
.privacyPolicy .accordion-item {
    background-color: #fff;
    border: none;
    color: #000646;
    border-bottom: 2px solid #d3d3d3 !important;
    outline: none !important;
}
.privacyPolicy ul li{
    font-size: 22px;
    margin-bottom: 0px;
    padding-bottom: 20px;
    color:#3c4067 !important;
}
.coming-soon-title{
    font-size: 36px;
}
.form-check label{
    font-size: 24px;
}
.our-services-block .card-body{
    min-height: auto;
    text-align: center;
    font-size: 24px;
}
.our-services-block{
    padding-top:80px;
    padding-bottom: 80px;
}
.service-icon img{
    width:64px;
}
.service-icon-title{
    font-size: 24px;
    line-height: initial;
    color: #000646;
}
.services-cards{
    margin-top:40px;
}
@media (min-width:700px) {
    .services-block-btn-p{
        border-left: 1px solid #d3d3d3;
    }
}
.services-block-btn-p p{
    font-size: 22px;
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 400;
    font-family: "Founders Grotesk",-apple-system,BlinkMacSystemFont,"Helvetica Neue",Helvetica,Arial,sans-serif;

}
.service-accordians .accordion-button:focus{
    border:1px solid #d3d3d3 !important;
}

.service-accordians .accordion-button::after{
    background-image: url(../../static/media/downArrow.8fa3e558.svg) !important;
}
.service-accordians .accordion-body{
    font-size: 22px;
    line-height: initial;
    padding-top: 30px;
    padding-bottom: 30px;
}
.service-accordians .accordion-item{
    border:none !important;
    background-color:transparent;
}
.service-accordians .border-top-none{
    border-top:none !important;
}
.service-accordians .accordion-header{
}
.service-accordians .accordion-body{
    border: 1px solid #d3d3d3;
    margin-top: -3px;
    border-top: none !important;
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}
.service-accordians .accordion-header .accordion-button{
    background-color: transparent;
    padding-top:18px;
    padding-bottom: 18px;
    border: 1px solid #d3d3d3;
    margin-top: 30px;
}
.service-accordians .accordion-header .collapsed{
    border-radius: 5px !important;
}
/** Press and Media **/
.new-Section-block{
    background: #f9fbfe;
    padding-top:60px;
    padding-bottom: 60px;
}
.new-Section-block p{
    font-size: 22px;
    line-height: initial;
    min-height: 160px;
    margin-top: 20px;
}
.bullet-tips-list li strong{
    font-family: 'Montserrat', sans-serif;
}
.new-Section-block .card-body{
    min-height: auto !important;
    height: auto;
}
.news-cards{
    margin-top:30px;
}
.news-cards:hover{
    background: #f7560f;
}
.news-cards:hover .card-text{
    color: #ffffff !important;
}
.news-cards:hover .card-link-read{
    color: #ffffff !important;
}
.dropdown-item {
    font-size: 22px;
    color: #3d2895;
    text-decoration: none;
    background-color: #f9fbfe;
}
.dropdown-menu {
    color: #472a80;
    background-color: #f9fbfe;
}
.dropdown .btn-primary{
    background: transparent;
    border-radius: 0px;
    border: none !important;
    color: #3d287b;
    font-size: 22px;
    font-weight: normal;
    padding-bottom: 0px;
}
.navbar-light .navbar-nav .active:hover{
    border-bottom: 0px !important;
}
.bullet-tips-list{
    padding-left: 20px;
}
.bullet-tips-list li::marker{
    font-size: 24px;
    color:#f7560f;
}
.card-link-read{
    color:#f7560f;
}
/** Blog **/
.blog-content-section{
    margin-top: 60px;
    padding-bottom: 70px;
}
.block-title{
    font-size: 28px;
    text-align: center;
}
.block-content-display{
    margin-top: 60px;
}
.blog-content-section .widget{
    margin-top:20px;
}
.blog-title-block{
    border-bottom: 2px solid #d3d3d3;
    padding-bottom: 10px;
    /* margin-bottom: 30px; */
    margin-bottom: 1.5rem;
}
@media (max-width:330px) {
    .topbar-flex .btn {
        background: #c1c1c1;
        border: 1px solid #c1c1c1;
        color: #321260;
        font-weight: normal;
        padding-left: 8px !important;
        padding-right: 8px !important;
        font-size: 10px !important;
        border-radius: 25px;
        width: 100%;
        box-shadow: none !important;
    }
    .topbar-flex .mobile-width-50 {
        padding-left: 5px;
        padding-right: 5px;
    }
}
.date{
    color: #9e9e9e;
}
.blog-subtitle{
    margin-top:10px;
    font-size: 22px;
}
.block-content-display p{
    font-size: 24px;
    line-height: initial;
}
.block-content-display div{
    margin-top: 40px;
}    
@media (min-width:700px) {
    .blog-section-block{
        padding-right:30px;
    }
    .widget img {
       max-width: 250px;
       width: 25%;
    }
}
.blog-about{
    background: #f9fbfe;
    padding: 20px;
    border:1px solid #d3d3d3;
    border-radius: 10px;
}
.blog-aboutTitle{
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
}
.blog-about img{
    margin: 0 10px 0 0;
}
#about img {
    float: left;
}
@media (max-width:700px) {
    .scroll-your-role{
        font-size: 18px !important;
    border-radius: 10px !important;
    }
}
